import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login.component';
import { LoginModule } from './login.module';

const routes: Routes = [
    { path: 'twitch/callback', component: LoginComponent }
];

export const Router: ModuleWithProviders<LoginModule> = RouterModule.forChild(routes);
