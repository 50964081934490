<div class="background">
    <section class="calendar-grid" (click)="clickDoor($event)">
        <div #days *ngFor="let item of arrayOfDay(25); let i = index;" class="day{{i}}">
            <div class="door">
                <div class="front">{{i}}</div>
                <div class="back"></div>
            </div>
        </div>
    </section>
</div>
<div class="ground"></div>
<footer>
    <p class="compteur">Participants : {{compteur}}</p>
    <ul class="socialIcons">
        <li class="facebook"><a href="https://www.facebook.com/Sratuke/" target="_blank" rel="noopener noreferrer"><i
                    class="fa fa-fx fa-facebook"></i>Facebook</a></li>
        <li class="twitter"><a href="https://twitter.com/Sratuke" target="_blank" rel="noopener noreferrer"><i
                    class="fa fa-fx fa-twitter"></i>Twitter</a></li>
        <li class="youtube"><a href="https://www.youtube.com/channel/UCwqf5q1dtKv1gFjqmb6xpFg" target="_blank"
                rel="noopener noreferrer"><i class="fa fa-fx fa-youtube"></i>Youtube</a></li>
        <li class="twitch"><a href="https://www.twitch.tv/sratuke" target="_blank" rel="noopener noreferrer"><i
                    class="fa fa-fx fa-twitch"></i>Twitch</a></li>
        <li class="discord"><a href="https://discord.gg/sratuke" target="_blank" rel="noopener noreferrer"><i
                    class="fa fa-fx fa-discord"></i>Discord</a></li>
        <li class="extension"><a
                href="https://chrome.google.com/webstore/detail/twitch-live-sratuke/gdhbppdfpgjgbepdbgiaehnobliaakhd?hl=fr"
                target="_blank" rel="noopener noreferrer"><i class="fa fa-fx fa-chrome"></i>Extension</a></li>
    </ul>
</footer>