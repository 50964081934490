import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router } from './login.routing';
import { LoginComponent } from './login.component';
import { LoginService } from './service/login.service';

@NgModule({
  imports: [
    CommonModule,
    Router
  ],
  exports: [
    LoginComponent
  ],
  declarations: [LoginComponent],
  providers: [
    LoginService
  ]
})
export class LoginModule { }
