import { CanActivate, Router, ActivatedRoute } from '@angular/router';
import { Injectable, Inject } from '@angular/core';
import { LoginService } from './login/service/login.service';
import { SESSION_STORAGE, StorageService } from 'ngx-webstorage-service';
import { environment } from '../environments/environment';

@Injectable()
export class SiteAuthGuard implements CanActivate {
  constructor(private router: Router,
    private loginService: LoginService,
    private activedRoute: ActivatedRoute,
    @Inject(SESSION_STORAGE) private storage: StorageService
  ) { }

  private readonly url = `https://id.twitch.tv/oauth2/authorize?client_id=${environment.clientId}&redirect_uri=${environment.twitchCallback}&response_type=code`;

  canActivate() {
    if (this.storage.get('id') && this.storage.get('pseudo')) {
      this.loginService.getAuth(this.storage.get('id'), this.storage.get('pseudo'))
        .subscribe(res => {
          if (!res.result) {
            alert('Erreur à la connexion');
            return false;
          }
        });
    } else {
      window.location.href = this.url;
      return false;
    }

    return true;
  }
}
