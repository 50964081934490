import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs';
import { Gains } from '../models/gains';
import { environment } from '../../../environments/environment';
import { Compteur } from '../models/compteur';

@Injectable({
  providedIn: 'root'
})
export class GainsService {

  private authUrl = `${environment.apiUrl}`;

  constructor(private http: HttpClient) { }

  public getGains(pseudo: string, id: string): Observable<Gains[]> {
    const url = `${this.authUrl}/avent/gains`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'name': pseudo,
        'id': id
      })
    };

    return this.http.get<Gains[]>(url, httpOptions);
  }

  public trigger(pseudo: string, id: string, noselected: number): Observable<any> {
    const url = `${this.authUrl}/avent/trigger/${noselected}`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'name': pseudo,
        'id': id
      })
    };

    return this.http.get<any>(url, httpOptions);
  }

  public getCompteur(): Observable<Compteur> {
    const url = `${this.authUrl}/avent/users/count`;

    return this.http.get<Compteur>(url);
  }
}
