import { Component, OnInit, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { LoginService } from './service/login.service';
import { Auth } from './models/auth';
import { SESSION_STORAGE, StorageService } from 'ngx-webstorage-service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  constructor(
    private router: Router,
    private activedRoute: ActivatedRoute,
    private loginService: LoginService,
    @Inject(SESSION_STORAGE) private storage: StorageService
  ) { }

  ngOnInit() {
    this.activedRoute.queryParams.subscribe(params => {
      const code = params['code'];
      const STORAGE_KEY_ID = 'id';
      const STORAGE_KEY_PSEUDO = 'pseudo';

      this.loginService
        .getToken(code)
        .subscribe((result: Auth) => {
          if (!result) {
            alert('Un problème est survenu lors de la connexion.');
            return;
          }

          this.storage.set(STORAGE_KEY_ID, JSON.stringify(result.id));
          this.storage.set(STORAGE_KEY_PSEUDO, JSON.stringify(result.user));
          this.router.navigate(
            ['/'],
            { relativeTo: this.activedRoute }
          );
        },
          err => alert(`Erreur à la connexion`)
        );
    });
  }

}
