import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { Auth } from '../models/auth';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  private authUrl = `${environment.apiUrl}`;

  constructor(private http: HttpClient) { }

  public getToken(code: string): Observable<Auth> {
    const url = `${this.authUrl}/avent/token/${code}`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    return this.http.get<Auth>(url, httpOptions);
  }

  public getAuth(id: string, pseudo: string): Observable<any> {
    const url = `${this.authUrl}/twitch/auth/${id}/${pseudo}`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    return this.http.get<any>(url, httpOptions);
  }
}
